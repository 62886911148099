import { Actions } from '../models/actions';
import {
  FETCH_TOR24_MATCHES_REQUEST, FETCH_TOR24_MATCHES_SUCCESS,
  FETCH_TOR24_MATCHES_ERROR, TOR24_TEAMS_ERROR,
  TOR24_TEAMS_REQUEST, TOR24_TEAMS_SUCCESS,
} from '../constants/actionTypes';
import { ExternalTeamsByHash, ExternalSystemsReducer, ExternalMatchesByHash } from '../models/externalSystemIntegration/externalSystemReference';
import { Tor24Team } from '../models/externalSystemIntegration/tor24Team';
import { SIGN_OUT_ERROR, SIGN_OUT_SUCCESS } from '../packages/authentication/constants';
import { MatchData } from '../models/Calendar';

export const initialState: ExternalSystemsReducer = {
  loading: false,
  externalTeamByHash: {},
  externalTeamById: [],
  externalMatchesByHash: {},
  externalMatchesById: [],
  error: false,
};

export default (state = initialState, action : Actions) => {
  switch (action.type) {
    case TOR24_TEAMS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TOR24_TEAMS_SUCCESS: {
      const teams = action.payload.teams.response;
      return {
        ...state,
        externalTeamByHash: teams
          .reduce((byHash: ExternalTeamsByHash, team: Tor24Team) => ({
            ...byHash,
            [team.id]: team,
          }), state.externalTeamByHash),
        externalTeamById: teams.map(({ id }: Tor24Team) => id),
        loading: false,
      };
    }
    case TOR24_TEAMS_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case FETCH_TOR24_MATCHES_SUCCESS: {
      const { payload: { matches } } = action;

      return {
        ...state,
        loading: false,
        externalMatchesByHash: matches
          .reduce((byHash: ExternalMatchesByHash, match: MatchData) => ({
            ...byHash,
            [match.externalId!]: match,
          }), state.externalMatchesByHash),
        externalMatchesById: matches.map(({ externalId }: MatchData) => externalId),
      };
    }
    case FETCH_TOR24_MATCHES_REQUEST:
      return {
        ...state,
        loading: true,
        externalTeamById: [],
        externalTeamByHash: {},
      };
    case FETCH_TOR24_MATCHES_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case SIGN_OUT_SUCCESS:
    case SIGN_OUT_ERROR:
      return initialState;
    default:
      return state;
  }
};
