import {
  EditTeamRequestAction, FetchClubRequestAction,
  FetchTeamIdRequestAction, FetchTeamRequestAction,
  FetchTeamsRequestAction, Tor24TeamsRequestAction,
} from '../../../../models/actions';
import { Team, TeamsByHash } from '../../../../models/Team';
import { Tor24Team } from '../../../../models/externalSystemIntegration/tor24Team';
import { Club } from '../../../../models/Club';

export type StateProps = {
  club: Club,
  fetchTeams: () => FetchTeamsRequestAction;
  fetchTeam: (id: number) => FetchTeamRequestAction;
  loadingExternalTeams: boolean;
  tor24Teams: (
    tor24ClubId: string,
  ) => Tor24TeamsRequestAction;
  editTeam: (
    team: Team,
    deactivateTor24Integration?: boolean
  ) => EditTeamRequestAction;
  fetchTeamId: (clubId: number) => FetchTeamIdRequestAction;
  clubId: number | null;
  selectedPlayers: number[];
  teamsByHash: TeamsByHash;
  loading: boolean;
  editionSuccessful: boolean;
  error: boolean;
  errorMessage?: string;
  externalTeamByHash: { [key: string]: Tor24Team };
  externalTeamById: number[],
  fetchClubById: () => FetchClubRequestAction
};

export const ERROR_MESSAGE_TRANSLATIONS: { [key: string] : string } = {
  'team.paypal_url.invalid': 'UPDATE_TEAM.PAYPAL_URL_INVALID_ERROR',
};

export interface EditTeamProps extends StateProps {
}
