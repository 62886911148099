import DatePicker from 'react-date-picker';
import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import get from 'lodash/get';
import { Form, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import InputPassword from '../InputPassword';
import styles from './FormFields.module.scss';
import { FormFieldProps } from './FormFieldsProps';
import Text from '../Text';

const FormFields = ({
  schema, values, handleChange, errors, inline,
}: FormFieldProps) => {
  const [translate] = useTranslation();

  return (
    <>
      {Object.keys(schema).map(fieldName => {
        const {
          type, label, options: selectOptions, required, disabled, min, max, step, placeholder,
        } = schema[fieldName];
        const value = get(values, fieldName);

        return (
          <Form.Field
            className={classNames(styles.formField, inline && styles.formFieldInline)}
            key={fieldName}
            required={required}
          >
            <label>
              {translate(label || fieldName.toUpperCase())}
            </label>

            <div className={styles.inputWithLabel}>
              {type === 'label' && (
                <Text bold>{value as string}</Text>
              )}
              {type === 'text' && (
                <Form.Input
                  error={errors[fieldName]}
                  name={fieldName}
                  disabled={disabled}
                  placeholder={placeholder && translate(placeholder)}
                  onChange={handleChange}
                  value={value || ''}
                />
              )}

              {type === 'password' && (
                <InputPassword
                  error={errors[fieldName]}
                  name={fieldName}
                  onChange={handleChange}
                  value={value || ''}
                />
              )}

              {type === 'number' && (
                <Form.Input
                  error={errors[fieldName]}
                  min={min || 0}
                  name={fieldName}
                  onChange={handleChange}
                  disabled={disabled}
                  type="number"
                  max={max}
                  step={step}
                  value={value || ''}
                />
              )}

              {type === 'select' && (
                <Form.Dropdown
                  clearable
                  error={errors[fieldName]}
                  lazyLoad
                  name={fieldName}
                  placeholder={placeholder && translate(placeholder)}
                  onChange={handleChange}
                  options={selectOptions ? selectOptions(translate) : []}
                  selection
                  disabled={disabled}
                  value={value || ''}
                />
              )}

              {type === 'country' && (
                <Form.Dropdown
                  clearable
                  error={errors[fieldName]}
                  lazyLoad
                  name={fieldName}
                  placeholder={placeholder && translate(placeholder)}
                  onChange={handleChange}
                  options={selectOptions ? selectOptions(translate) : []}
                  search
                  selection
                  value={value || ''}
                />
              )}

              {type === 'date' && (
                <DatePicker
                  calendarIcon={<Icon name="calendar outline" />}
                  className={classNames(styles.datePicker, 'ui input')}
                  clearIcon={<Icon name="remove" />}
                  format="dd.MM.y"
                  value={value ? new Date(value) : undefined}
                  onChange={(date: Date | Date[]) => handleChange(
                    null,
                    { name: fieldName, value: date ? moment(date as Date).format('YYYY-MM-DD') : '' },
                  )}
                />
              )}
            </div>
          </Form.Field>
        );
      })}
    </>
  );
};

export default FormFields;
