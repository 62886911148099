import React, { FC, useEffect, useState } from 'react';
import get from 'lodash/get';
import queryString from 'query-string';
import { Redirect, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Form, Message, Button, Header, Image, Radio,
} from 'semantic-ui-react';

import { push } from 'connected-react-router';
import AuthenticationFormsWrapper from '../../components/AuthenticationFormsWrapper';
import FormFields from '../../components/FormFields';
import styles from './SignUp.module.scss';
import useFocusFirstInput from '../../hooks/useFocusFirstInput';
import useForm from '../../hooks/useForm';
import {
  SIGN_IN_ROUTE, DATA_PRIVACY_ROUTE, TERMS_ROUTE, USER_CONSENT_ROUTE,
} from '../../constants/routes';
import {
  SignUpProps, EMAIL, FIRST_NAME, LAST_NAME,
} from './SignUpProps';
import { UrlParams } from '../../constants/urlParams';
import { createSchema, validationSchema } from './schema';
import { jwtDecoder } from '../../utils/decoders/jwtDecoder';
import { InvitationQueryParams } from '../../models/InvitationData';
import { CUSTOMER_SERVICE_EMAIL_11TEAMSPORTS } from '../../constants/customizations';

export const SIGN_UP_ERROR_MESSAGES = {
  ALREADY_IN_USE: 'username.already_in_use',
};

const SignUp: FC<SignUpProps> = ({
  clubLogoUrl,
  fetchTokenDetails,
  loading,
  resetSignup,
  location,
  setUserConsentApproved,
  serverError,
  signUpWithToken,
  consentApproved,
  tokenDetails,
}) => {
  const [translate, i18n] = useTranslation();
  const [termsAndConditionsRadio, setTermsAndConditionsRadio] = useState(false);
  const [marketingConsentRadio, setMarketingConsentRadio] = useState(consentApproved);
  const queryParams = queryString.parse(location.search);
  let invitation: InvitationQueryParams | null = null;
  if (queryParams[UrlParams.INVITATION_TOKEN]) {
    invitation = jwtDecoder(
      queryParams[UrlParams.INVITATION_TOKEN] as string,
      UrlParams.INVITATION_TOKEN,
    );
  } else {
    push(SIGN_IN_ROUTE);
  }
  const inviteToken = invitation && invitation.invitationToken;
  const emailParam = invitation && invitation.receiverEmail;
  const receiverFirstNameParam = invitation && invitation.receiverFirstName;
  const receiverLastNameParam = invitation && invitation.receiverLastName;
  const errorMessage = get(serverError, 'message', null);
  const {
    values,
    errors,
    handleChange,
    setValues,
    validate,
  } = useForm(validationSchema);
  useFocusFirstInput();

  useEffect(() => {
    resetSignup();
  }, [resetSignup]);

  useEffect(() => {
    if (inviteToken) {
      fetchTokenDetails(inviteToken);
    }
  }, [fetchTokenDetails, inviteToken]);

  const handleSubmit = () => {
    if (validate() && inviteToken && tokenDetails) {
      const {
        firstName,
        lastName,
        email,
        password,
      } = values;
      signUpWithToken({
        username: email,
        email,
        firstName,
        lastName,
        password,
        marketingConsentGranted: marketingConsentRadio,
      }, inviteToken, tokenDetails);
    }
  };

  useEffect(() => {
    if (emailParam) {
      setValues((state: any) => ({
        ...state,
        [EMAIL]: emailParam.replace(' ', '+'),
        [FIRST_NAME]: receiverFirstNameParam || '',
        [LAST_NAME]: receiverLastNameParam || '',
      }));
    }
  }, [emailParam, receiverFirstNameParam, receiverLastNameParam, setValues]);

  if (!inviteToken) {
    return <Redirect to={SIGN_IN_ROUTE} />;
  }

  return (
    <AuthenticationFormsWrapper clubLogoUrl={clubLogoUrl}>
      <div className={styles.container}>
        <Header as="h2" className={styles.header}>
          {translate('REGISTER')}

          {clubLogoUrl && (
            <Image src={clubLogoUrl} className={styles.clubLogo} />
          )}
        </Header>
        { errorMessage !== SIGN_UP_ERROR_MESSAGES.ALREADY_IN_USE && (
          <div className={styles.content}>
            <span>{`${translate('I_DO_HAVE_AN_ACCOUNT')}, `}</span>
            <Link to={{ pathname: SIGN_IN_ROUTE, search: location.search }}>{translate('LET_ME_LOGIN')}</Link>
          </div>
        )}
        <Form loading={loading} className={styles.form}>
          <FormFields
            errors={errors}
            handleChange={handleChange}
            schema={createSchema}
            values={values}
          />
          <div
            className={styles.radioWrapper}
            onClick={() => setTermsAndConditionsRadio(state => !state)}
          >
            <Radio
              checked={termsAndConditionsRadio}
              toggle
              className={styles.radioToggle}
              name="terms_conditions"
            />
            <span className={[styles.radioLabel, styles.dotEnded].join(' ')}>
              {translate('I_ACCEPT_THE')}
              <Link to={TERMS_ROUTE}>{translate('TERMS_AND_CONDITIONS_SHORT')}</Link>
              {translate('AND')}
              {/*
              // @ts-ignore */}
              {(i18n.language).slice(0, 2) === 'de' && ' der '}
              <Link to={DATA_PRIVACY_ROUTE}>{translate('DATA_PRIVACY_POLICY')}</Link>
              {/*
              // @ts-ignore */}
              {(i18n.language).slice(0, 2) === 'de' && ' zu'}
            </span>
          </div>
          <div
            className={[styles.radioWrapper, styles.secondRadio].join(' ')}
            onClick={() => {
              setUserConsentApproved(!marketingConsentRadio);
              setMarketingConsentRadio(state => !state);
            }}
          >
            <Radio
              toggle
              checked={marketingConsentRadio}
              className={styles.radioToggle}
              name="marketing_consent"
            />
            <span className={styles.radioLabel}>
              {translate('ALLOW_TO_SEND_ADS')}
              <Link to={USER_CONSENT_ROUTE}>{translate('MORE_INFO')}</Link>
            </span>
          </div>
          {errorMessage && errorMessage !== SIGN_UP_ERROR_MESSAGES.ALREADY_IN_USE && (
            <Message size="mini" header={translate(errorMessage.toUpperCase())} negative />
          )}
          {errorMessage === SIGN_UP_ERROR_MESSAGES.ALREADY_IN_USE && (
            <Button fluid basic primary as={Link} to={`${SIGN_IN_ROUTE}?${UrlParams.INVITATION_TOKEN}=${queryParams[UrlParams.INVITATION_TOKEN]}`}>
              {translate('ACCEPT_INVITE_ALREADY_EXISTING_MEMBER')}
            </Button>
          )}
          <Button
            className={styles.submit}
            content={translate('REGISTER')}
            disabled={loading || !termsAndConditionsRadio}
            fluid
            primary
            onClick={handleSubmit}
          />
          <p className={[styles.content, styles.sidenote].join(' ')}>
            {translate('PROBLEMS_SIGNING_UP_CUSTOMER_SUPPORT_TEXT')}
            &nbsp;
            <a className={styles.csLink} href={`mailto:${CUSTOMER_SERVICE_EMAIL_11TEAMSPORTS}`}>{`${translate('PROBLEMS_SIGNING_UP_CUSTOMER_SUPPORT_LINK')}.`}</a>
          </p>
        </Form>
      </div>
    </AuthenticationFormsWrapper>
  );
};

export default SignUp;
