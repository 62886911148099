import { ActionsObservable, StateObservable, ofType } from 'redux-observable';
import { mergeMap, withLatestFrom } from 'rxjs/operators';

import { MembershipResponse } from '../../models/general/Membership';
import { NtsUserMembership } from '../../models/Response';
import { Roles } from '../../constants/roles';
import { SIGN_IN_SUCCESS } from '../../packages/authentication/constants';
import { SignInSuccessAction } from '../../models/actions';
import { State } from '../../models/State';
import { fetchClubLogoRequest, fetchClubRequest } from '../../actions/clubs';
import { fetchUserInfoRequest, setUser } from '../../actions/user';
import { fetchCatalogRequest } from '../../actions/catalogs';
import { setApplicationParameters, acceptInvitationRequest } from '../../actions/application';
import { fetchTeamIdRequest } from '../../actions/teams';

const parseMemberships = (memberships: MembershipResponse[]): NtsUserMembership => (
  memberships.reduce((fin, { organizationId, roles }) => ({
    ...fin,
    [organizationId]: roles,
  }), {})
);

const fetchClubOrLogo = (clubId: number, memberships: NtsUserMembership) => (
  memberships[clubId].includes(Roles.CLUB_ADMIN)
    ? fetchClubRequest()
    : fetchClubLogoRequest()
);

export default (
  action$: ActionsObservable<SignInSuccessAction>,
  state$: StateObservable<State>,
) => action$.pipe(
  ofType(SIGN_IN_SUCCESS),
  withLatestFrom(state$),
  mergeMap(([{
    payload: {
      signInResponse: {
        user: {
          email, firstName, lastName, username, id,
          memberships, currentlyUsedOrganizationId, clubType,
        },
      },
      params,
    },
  }, { signUp, router }]) => {
    const parsedMemberships = parseMemberships(memberships);
    return [
      setApplicationParameters({
        memberships: parsedMemberships,
        clubId: currentlyUsedOrganizationId,
      }),
      signUp.serverError
        && router.location.query.invitationJwtToken
        && params.acceptInvitation
        && acceptInvitationRequest(router.location.query.invitationJwtToken!),
      currentlyUsedOrganizationId && fetchTeamIdRequest(currentlyUsedOrganizationId),
      currentlyUsedOrganizationId
        && memberships.length
        && fetchClubOrLogo(currentlyUsedOrganizationId, parsedMemberships),
      currentlyUsedOrganizationId
        && fetchCatalogRequest(),
      setUser({
        id, email, firstName, lastName, username, clubType,
      }),
      fetchUserInfoRequest(),
    ].filter(Boolean);
  }),
);
