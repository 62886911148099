import { FormSchema } from '../../models/Form';
import {
  hasCapitalLetter,
  hasMinLength,
  hasNumber,
  isEqualAs,
  isRequired,
  isValidEmail,
} from '../../utils/formValidators';

export const createSchema: FormSchema = ({
  firstName: {
    type: 'text',
    label: 'FIRSTNAME',
  },
  lastName: {
    type: 'text',
    label: 'LASTNAME',
  },
  email: {
    type: 'text',
    label: 'EMAIL',
  },
  password: {
    type: 'password',
    label: 'PASSWORD.PASSWORD',
  },
  confirmPassword: {
    type: 'password',
    label: 'CONFIRMPASSWORD',
  },
});

export const validationSchema = {
  firstName: [isRequired],
  lastName: [isRequired],
  email: [isRequired, isValidEmail],
  password: [isRequired, hasCapitalLetter, hasNumber, hasMinLength(8)],
  confirmPassword: [isRequired, isEqualAs('password')],
};
