import { createLogger } from 'redux-logger';
import {
  createStore,
  applyMiddleware,
  compose,
  Middleware,
  combineReducers,
} from 'redux';
import storage from 'redux-persist/lib/storage';
import { ajax } from 'rxjs/ajax';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import { persistStore, persistReducer } from 'redux-persist';
import { createBrowserHistory } from 'history';
import { routerMiddleware, connectRouter } from 'connected-react-router';

import reducers from './reducers';
import epics from './epics';
import injectAuthentication from './packages/authentication';
import BASE_URL from './config';
import { APP_NAME } from './constants/customizations';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['authentication', 'application', 'user', 'clubs', 'signUp', 'catalog'],
};

// @ts-ignore
// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const history = createBrowserHistory({ basename: process.env.PUBLIC_URL });
const epicMiddleware = createEpicMiddleware();

const middleware: Array<Middleware> = [routerMiddleware(history), epicMiddleware];

if (process.env.NODE_ENV !== 'production') {
  middleware.push(createLogger());
}

const [authReducer, authEpics] = injectAuthentication({ baseUrl: BASE_URL, app: APP_NAME });

const combinedReducers = combineReducers({
  router: connectRouter(history),
  ...reducers,
  ...authReducer,
} as any);

const persistedReducer = persistReducer(persistConfig, combinedReducers);

const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(...middleware)),
);

const persistor = persistStore(store);

epicMiddleware.run((...args) => combineEpics(...[
  ...epics,
  ...authEpics as any,
])(...args, { ajax, localStorage }));

export { store, persistor };
