import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import CreateTeam from './CreateTeam';
import { State } from '../../../../models/State';
import { DispatchProps, StateProps } from './CreateTeamProps';
import { createTeamRequest } from '../../../../actions/teams';

const mapStatesToProps = ({
  teams: { error, loading },
  application: { clubId },
}: State): StateProps => ({
  clubId,
  error,
  loading,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  createTeam: bindActionCreators(createTeamRequest, dispatch),
});

export default connect(mapStatesToProps, mapDispatchToProps)(CreateTeam);
