import React, {
  FC, useCallback, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, Dropdown, DropdownItemProps, Modal,
} from 'semantic-ui-react';
import { ChangeTeamSeasonPopupProps } from './ChangeTeamSeasonPopupProps';
import Seasons from '../../../../constants/seasons';
import TransferOptions from '../../../../constants/transferOptions';
import styles from './ChangeTeamSeasonPopup.module.scss';

const ChangeTeamSeasonPopup: FC<ChangeTeamSeasonPopupProps> = ({
  teamId, teamName, closePopup, isOpen, teamSeasonTransfer, switchingSeason, switchSeasonSuccess,
}) => {
  const [translate] = useTranslation();
  const [selectedSeason, setSelectedSeason] = useState<Seasons>();
  const [playerLineUpTransferOption, setPlayerLineUpTransferOption] = useState<TransferOptions>();
  const [treasurerTransferOption, setTreasurerTransferOption] = useState<TransferOptions>();
  const [teamLeadTransferOption, setTeamLeadTransferOption] = useState<TransferOptions>();

  const [seasonOptions] = useState<DropdownItemProps[]>(
    Object.keys(Seasons).map((key: string) => ({
      value: key,
      text: translate(key),
      key,
    })),
  );
  const [transferOptions] = useState<DropdownItemProps[]>(
    Object.keys(TransferOptions).map((key: string) => ({
      value: key,
      text: translate(key),
      key,
    })),
  );

  const [teamLeadTransferOptions] = useState<DropdownItemProps[]>(
    Object.keys(TransferOptions).filter(val => val !== TransferOptions.MOVE).map((key: string) => ({
      value: key,
      text: translate(key),
      key,
    })),
  );

  useEffect(() => {
    if (!switchingSeason && switchSeasonSuccess) {
      closePopup();
    }
  }, [switchingSeason, switchSeasonSuccess, closePopup]);

  const save = useCallback(() => {
    if (
      selectedSeason
        && playerLineUpTransferOption
        && treasurerTransferOption
        && teamLeadTransferOption) {
      teamSeasonTransfer(
        teamId,
        selectedSeason,
        playerLineUpTransferOption,
        treasurerTransferOption,
        teamLeadTransferOption,
      );
    }
  }, [
    selectedSeason,
    playerLineUpTransferOption,
    treasurerTransferOption,
    teamLeadTransferOption,
    teamId,
    teamSeasonTransfer,
  ]);

  return (
    <Modal
      open={isOpen}
      onClose={closePopup}
      closeIcon
      closeOnDimmerClick={false}
    >
      <Modal.Header content={translate('CHANGE_SEASON_FOR', { teamName })} />
      <Modal.Content>
        <div className={styles.modalContentWrapper}>
          <Dropdown
            selection
            fluid
            value={selectedSeason}
            placeholder={translate('SEASON')}
            options={seasonOptions}
            onChange={(e, data) => setSelectedSeason(data.value as Seasons)}
          />
          <Dropdown
            selection
            fluid
            value={playerLineUpTransferOption}
            placeholder={translate('PLAYER_LINEUP_TRANSFER_OPTION')}
            options={transferOptions}
            onChange={(e, data) => setPlayerLineUpTransferOption(data.value as TransferOptions)}
          />
          <Dropdown
            selection
            fluid
            value={treasurerTransferOption}
            placeholder={translate('TREASURER_LINEUP_TRANSFER_OPTION')}
            options={transferOptions}
            onChange={(e, data) => setTreasurerTransferOption(data.value as TransferOptions)}
          />
          <Dropdown
            selection
            fluid
            value={teamLeadTransferOption}
            placeholder={translate('TEAM_LEAD_LINEUP_TRANSFER_OPTION')}
            options={teamLeadTransferOptions}
            onChange={(e, data) => setTeamLeadTransferOption(data.value as TransferOptions)}
          />
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button basic onClick={closePopup}>{translate('CANCEL')}</Button>
        <Button
          primary
          disabled={!selectedSeason
              || !playerLineUpTransferOption
              || !treasurerTransferOption
              || !teamLeadTransferOption}
          onClick={() => save()}
        >
          {translate('SAVE')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ChangeTeamSeasonPopup;
