export const SET_MENU_EXPANDED = 'SET_MENU_EXPANDED';
export const SET_USER_CONSENT_APPROVED = 'SET_USER_CONSENT_APPROVED';
export const SIGN_UP_WITH_TOKEN_REQUEST = 'SIGN_UP_WITH_TOKEN_REQUEST';
export const SIGN_UP_WITH_TOKEN_ERROR = 'SIGN_UP_WITH_TOKEN_ERROR';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_ERROR = 'SIGN_UP_ERROR';

export const STARTUP = 'STARTUP';

export const TOGGLE_INVITATION_MODAL = 'TOGGLE_INVITATION_MODAL';
export const GET_USER = 'GET_USER';
export const GET_USER_ERROR = 'GET_USER_ERROR';
export const SET_USER = 'SET_USER';
export const REDUX_INIT = '@@INIT';
export const SET_APPLICATION_PARAMETERS = 'SET_APPLICATION_PARAMETERS';

export const CREATE_EVENT_REQUEST = 'CREATE_EVENT_REQUEST';
export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS';
export const CREATE_EVENT_ERROR = 'CREATE_EVENT_ERROR';
export const RESET_EVENT_CREATED = 'RESET_EVENT_CREATED';

export const CREATE_EVENTS_REQUEST = 'CREATE_EVENTS_REQUEST';
export const CREATE_EVENTS_SUCCESS = 'CREATE_EVENTS_SUCCESS';
export const CREATE_EVENTS_ERROR = 'CREATE_EVENTS_ERROR';

export const RESET_SIGNUP = 'RESET_SIGNUP';
export const RESET_USER_DATA = 'RESET_USER_DATA';

export const CHECK_EMAIL_AVAILABILITY_REQUEST = 'CHECK_EMAIL_AVAILABILITY_REQUEST';
export const CHECK_EMAIL_AVAILABILITY_SUCCESS = 'CHECK_EMAIL_AVAILABILITY_SUCCESS';
export const CHECK_EMAIL_AVAILABILITY_ERROR = 'CHECK_EMAIL_AVAILABILITY_ERROR';

export const FETCH_EVENTS_REQUEST = 'FETCH_EVENTS_REQUEST';
export const FETCH_EVENTS_SUCCESS = 'FETCH_EVENTS_SUCCESS';
export const FETCH_EVENTS_ERROR = 'FETCH_EVENTS_ERROR';

export const ACCEPT_INVITATION_REQUEST = 'ACCEPT_INVITATION_REQUEST';
export const ACCEPT_INVITATION_SUCCESS = 'ACCEPT_INVITATION_SUCCESS';
export const ACCEPT_INVITATION_ERROR = 'ACCEPT_INVITATION_ERROR';

export const FETCH_EVENT_REQUEST = 'FETCH_EVENT_REQUEST';
export const FETCH_EVENT_SUCCESS = 'FETCH_EVENT_SUCCESS';
export const FETCH_EVENT_ERROR = 'FETCH_EVENT_ERROR';

export const FETCH_TOR24_MATCHES_REQUEST = 'FETCH_TOR24_MATCHES_REQUEST';
export const FETCH_TOR24_MATCHES_SUCCESS = 'FETCH_TOR24_MATCHES_SUCCESS';
export const FETCH_TOR24_MATCHES_ERROR = 'FETCH_TOR24_MATCHES_ERROR';

export const DELETE_TEAM_REQUEST = 'DELETE_TEAM_REQUEST';
export const DELETE_TEAM_SUCCESS = 'DELETE_TEAM_SUCCESS';
export const DELETE_TEAM_ERROR = 'DELETE_TEAM_ERROR';

export const UPDATE_EVENT_REQUEST = 'UPDATE_EVENT_REQUEST';
export const UPDATE_EVENT_SUCCESS = 'UPDATE_EVENT_SUCCESS';
export const UPDATE_EVENT_ERROR = 'UPDATE_EVENT_ERROR';

export const DELETE_EVENT_REQUEST = 'DELETE_EVENT_REQUEST';
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';
export const DELETE_EVENT_ERROR = 'DELETE_EVENT_ERROR';

export const BULK_DELETE_EVENT_REQUEST = 'BULK_DELETE_EVENT_REQUEST';
export const BULK_DELETE_EVENT_SUCCESS = 'BULK_DELETE_EVENT_SUCCESS';
export const BULK_DELETE_EVENT_ERROR = 'BULK_DELETE_EVENT_ERROR';

export const CANCEL_EVENT_REQUEST = 'CANCEL_EVENT_REQUEST';
export const CANCEL_EVENT_SUCCESS = 'CANCEL_EVENT_SUCCESS';
export const CANCEL_EVENT_ERROR = 'CANCEL_EVENT_ERROR';

export const UPLOAD_EVENTS_REQUEST = 'UPLOAD_EVENTS_REQUEST';
export const UPLOAD_EVENTS_SUCCESS = 'UPLOAD_EVENTS_SUCCESS';
export const UPLOAD_EVENTS_ERROR = 'UPLOAD_EVENTS_ERROR';

export const UPDATE_RESULT_REQUEST = 'UPDATE_RESULT_REQUEST';
export const UPDATE_RESULT_SUCCESS = 'UPDATE_RESULT_SUCCESS';
export const UPDATE_RESULT_ERROR = 'UPDATE_RESULT_ERROR';

export const RESET_EVENT = 'RESET_EVENT';

export const FETCH_TEAMS_REQUEST = 'FETCH_TEAMS_REQUEST';
export const FETCH_TEAMS_SUCCESS = 'FETCH_TEAMS_SUCCESS';
export const FETCH_TEAMS_ERROR = 'FETCH_TEAMS_ERROR';

export const FETCH_TEAM_REQUEST = 'FETCH_TEAM_REQUEST';
export const FETCH_TEAM_SUCCESS = 'FETCH_TEAM_SUCCESS';
export const FETCH_TEAM_ERROR = 'FETCH_TEAM_ERROR';

export const DELETE_DATA_REQUEST = 'DELETE_DATA_REQUEST';
export const DELETE_DATA_SUCCESS = 'DELETE_DATA_SUCCESS';
export const DELETE_DATA_ERROR = 'DELETE_DATA_ERROR';

export const FETCH_TEAM_ID_REQUEST = 'FETCH_TEAM_ID_REQUEST';
export const FETCH_TEAM_ID_ERROR = 'FETCH_TEAM_ID_ERROR';

export const FETCH_CATALOG_REQUEST = 'FETCH_CATALOG_REQUEST';
export const FETCH_CATALOG_SUCCESS = 'FETCH_CATALOG_SUCCESS';
export const FETCH_CATALOG_ERROR = 'FETCH_CATALOG_ERROR';

export const FETCH_USER_INFO_REQUEST = 'FETCH_USER_INFO_REQUEST';
export const FETCH_USER_INFO_SUCCESS = 'FETCH_USER_INFO_SUCCESS';
export const FETCH_USER_INFO_ERROR = 'FETCH_USER_INFO_ERROR';

export const FETCH_AUTH_USER_REQUEST = 'FETCH_AUTH_USER_REQUEST';
export const FETCH_AUTH_USER_SUCCESS = 'FETCH_AUTH_USER_SUCCESS';
export const FETCH_AUTH_USER_ERROR = 'FETCH_AUTH_USER_ERROR';

export const FETCH_CLUB_REQUEST = 'FETCH_CLUB_REQUEST';
export const FETCH_CLUB_SUCCESS = 'FETCH_CLUB_SUCCESS';
export const FETCH_CLUB_ERROR = 'FETCH_CLUB_ERROR';

export const FETCH_CLUB_LOGO_REQUEST = 'FETCH_CLUB_LOGO_REQUEST';
export const FETCH_CLUB_LOGO_SUCCESS = 'FETCH_CLUB_LOGO_SUCCESS';
export const FETCH_CLUB_LOGO_ERROR = 'FETCH_CLUB_LOGO_ERROR';

export const UPLOAD_CLUB_LOGO_REQUEST = 'UPLOAD_CLUB_LOGO_REQUEST';
export const UPLOAD_CLUB_LOGO_SUCCESS = 'UPLOAD_CLUB_LOGO_SUCCESS';
export const UPLOAD_CLUB_LOGO_ERROR = 'UPLOAD_CLUB_LOGO_ERROR';

export const UPLOAD_AVATAR_REQUEST = 'UPLOAD_AVATAR_REQUEST';
export const UPLOAD_AVATAR_SUCCESS = 'UPLOAD_AVATAR_SUCCESS';
export const UPLOAD_AVATAR_ERROR = 'UPLOAD_AVATAR_ERROR';

export const CREATE_TEAM_REQUEST = 'CREATE_TEAM_REQUEST';
export const CREATE_TEAM_SUCCESS = 'CREATE_TEAM_SUCCESS';
export const CREATE_TEAM_ERROR = 'CREATE_TEAM_ERROR';

export const ACTIVE_TEAMS_SUCCESS = 'ACTIVE_TEAMS_SUCCESS';

export const SEND_INVITATION = 'SEND_INVITATION';
export const SEND_INVITATION_SUCCESS = 'SEND_INVITATION_SUCCESS';
export const SEND_INVITATION_ERROR = 'SEND_INVITATION_ERROR';

export const CREATE_PLAYER_REQUEST = 'CREATE_PLAYER_REQUEST';
export const CREATE_PLAYER_SUCCESS = 'CREATE_PLAYER_SUCCESS';
export const CREATE_PLAYER_ERROR = 'CREATE_PLAYER_ERROR';

export const FETCH_PLAYER_REQUEST = 'FETCH_PLAYER_REQUEST';
export const FETCH_PLAYER_SUCCESS = 'FETCH_PLAYER_SUCCESS';
export const FETCH_PLAYER_ERROR = 'FETCH_PLAYER_ERROR';

export const UPDATE_PLAYER_REQUEST = 'UPDATE_PLAYER_REQUEST';
export const UPDATE_PLAYER_SUCCESS = 'UPDATE_PLAYER_SUCCESS';
export const UPDATE_PLAYER_ERROR = 'UPDATE_PLAYER_ERROR';

export const DELETE_ORDERS_REQUEST = 'DELETE_ORDERS_REQUEST';
export const DELETE_ORDERS_SUCCESS = 'DELETE_ORDERS_SUCCESS';
export const DELETE_ORDERS_ERROR = 'DELETE_ORDERS_ERROR';

export const FETCH_PLAYERS_REQUEST = 'FETCH_PLAYERS_REQUEST';
export const FETCH_PLAYERS_SUCCESS = 'FETCH_PLAYERS_SUCCESS';
export const FETCH_PLAYERS_ERROR = 'FETCH_PLAYERS_ERROR';

export const FETCH_STAFF_REQUEST = 'FETCH_STAFF_REQUEST';
export const FETCH_STAFF_SUCCESS = 'FETCH_STAFF_SUCCESS';
export const FETCH_STAFF_ERROR = 'FETCH_STAFF_ERROR';

export const DELETE_MEMBERS_REQUEST = 'DELETE_MEMBERS_REQUEST';
export const DELETE_MEMBERS_SUCCESS = 'DELETE_MEMBERS_SUCCESS';
export const DELETE_MEMBERS_ERROR = 'DELETE_MEMBERS_ERROR';

export const DELETE_MEMBER_REQUEST = 'DELETE_MEMBER_REQUEST';
export const DELETE_MEMBER_SUCCESS = 'DELETE_MEMBER_SUCCESS';
export const DELETE_MEMBER_ERROR = 'DELETE_MEMBER_ERROR';

export const FETCH_TEAM_INVITE_TOKEN_REQUEST = 'FETCH_TEAM_INVITE_TOKEN_REQUEST';
export const FETCH_TEAM_INVITE_TOKEN_SUCCESS = 'FETCH_TEAM_INVITE_TOKEN_SUCCESS';
export const FETCH_TEAM_INVITE_TOKEN_ERROR = 'FETCH_TEAM_INVITE_TOKEN_ERROR';

export const EDIT_TEAM_REQUEST = 'EDIT_TEAM_REQUEST';
export const EDIT_TEAM_SUCCESS = 'EDIT_TEAM_SUCCESS';
export const EDIT_TEAM_ERROR = 'EDIT_TEAM_ERROR';

export const GENERATE_INVITE_TOKEN_REQUEST = 'GENERATE_INVITE_TOKEN_REQUEST';
export const GENERATE_INVITE_TOKEN_SUCCESS = 'GENERATE_INVITE_TOKEN_SUCCSS';
export const GENERATE_INVITE_TOKEN_ERROR = 'GENERATE_INVITE_TOKEN_ERROR';

export const RESEND_INVITATION_REQUEST = 'RESEND_INVITATION_REQUEST';
export const RESEND_INVITATION_SUCCESS = 'RESEND_INVITATION_SUCCESS';
export const RESEND_INVITATION_ERROR = 'RESEND_INVITATION_ERROR';

export const DEACTIVATE_INVITE_TOKEN_REQUEST = 'DEACTIVATE_INVITE_TOKEN_REQUEST';
export const DEACTIVATE_INVITE_TOKEN_SUCCESS = 'DEACTIVATE_INVITE_TOKEN_SUCCSS';
export const DEACTIVATE_INVITE_TOKEN_ERROR = 'DEACTIVATE_INVITE_TOKEN_ERROR';

export const BULK_DEACTIVATE_INVITE_TOKEN_REQUEST = 'BULK_DEACTIVATE_INVITE_TOKEN_REQUEST';
export const BULK_DEACTIVATE_INVITE_TOKEN_SUCCESS = 'BULK_DEACTIVATE_INVITE_TOKEN_SUCCESS';
export const BULK_DEACTIVATE_INVITE_TOKEN_ERROR = 'BULK_DEACTIVATE_INVITE_TOKEN_ERROR';

export const FETCH_FILE_REQUEST = 'FETCH_FILE_REQUEST';
export const FETCH_FILE_SUCCESS = 'FETCH_FILE_SUCCESS';
export const FETCH_FILE_ERROR = 'FETCH_FILE_ERROR';

export const FETCH_TEAM_ORDERS_REQUEST = 'FETCH_TEAM_ORDERS_REQUEST';
export const FETCH_TEAM_ORDERS_SUCCESS = 'FETCH_TEAM_ORDERS_SUCCESS';
export const FETCH_TEAM_ORDERS_ERROR = 'FETCH_TEAM_ORDERS_ERROR';

export const UPSERT_ORDER_REQUEST = 'UPSERT_ORDER_REQUEST';
export const UPSERT_ORDER_SUCCESS = 'UPSERT_ORDER_SUCCESS';
export const UPSERT_ORDER_ERROR = 'UPSERT_ORDER_ERROR';

export const FETCH_TOKEN_DETAILS_REQUEST = 'FETCH_TOKEN_DETAILS_REQUEST';
export const FETCH_TOKEN_DETAILS_SUCCESS = 'FETCH_TOKEN_DETAILS_SUCCESS';
export const FETCH_TOKEN_DETAILS_ERROR = 'FETCH_TOKEN_DETAILS_ERROR';

export const VALIDATE_HOBBY_TEAM_TOKEN_REQUEST = 'VALIDATE_HOBBY_TEAM_TOKEN_REQUEST';
export const VALIDATE_HOBBY_TEAM_TOKEN_SUCCESS = 'VALIDATE_HOBBY_TEAM_TOKEN_SUCCESS';
export const VALIDATE_HOBBY_TEAM_TOKEN_ERROR = 'VALIDATE_HOBBY_TEAM_TOKEN_ERROR';

export const FETCH_TAGS_REQUEST = 'FETCH_TAGS_REQUEST';
export const FETCH_TAGS_SUCCESS = 'FETCH_TAGS_SUCCESS';
export const FETCH_TAGS_ERROR = 'FETCH_TAGS_ERROR';

export const FETCH_EMBELLISHMENTS_ERROR = 'FETCH_EMBELLISHMENTS_ERROR';
export const FETCH_EMBELLISHMENTS_REQUEST = 'FETCH_EMBELLISHMENTS_REQUEST';
export const FETCH_EMBELLISHMENTS_SUCCESS = 'FETCH_EMBELLISHMENTS_SUCCESS';

export const CHANGE_ORDER_STATUS_REQUEST = 'CHANGE_ORDER_STATUS_REQUEST';
export const CHANGE_ORDER_STATUS_SUCCESS = 'CHANGE_ORDER_STATUS_SUCCESS';
export const CHANGE_ORDER_STATUS_ERROR = 'CHANGE_ORDER_STATUS_ERROR';
export const SET_STATUS = 'SET_STATUS';
export const SET_STATUS_SUCCESS = 'SET_STATUS_SUCCESS';
export const SET_STATUS_ERROR = 'SET_STATUS_ERROR';

export const FETCH_ORDER_REQUEST = 'FETCH_ORDER_REQUEST';
export const FETCH_ORDER_SUCCESS = 'FETCH_ORDER_SUCCESS';
export const FETCH_ORDER_ERROR = 'FETCH_ORDER_ERROR';

export const ORDER_TOGGLE_PLAYER_SELECTION = 'ORDER_TOGGLE_PLAYER_SELECTION';
export const ORDER_TOGGLE_STAFF_SELECTION = 'ORDER_TOGGLE_STAFF_SELECTION';
export const ORDER_TOGGLE_ALL_PLAYERS_SELECTION = 'ORDER_TOGGLE_ALL_PLAYERS_SELECTION';
export const ORDER_TOGGLE_PRODUCT_SELECTION = 'ORDER_TOGGLE_PRODUCT_SELECTION';
export const ORDER_TOGGLE_ALL_PRODUCTS_SELECTION = 'ORDER_TOGGLE_ALL_PRODUCTS_SELECTION';
export const ORDER_SELECT_PLAYERS = 'ORDER_SELECT_PLAYERS';
export const ORDER_SELECT_STAFF = 'ORDER_SELECT_STAFF';
export const ORDER_SELECT_PRODUCTS = 'ORDER_SELECT_PRODUCTS';
export const ORDER_ADD_ITEMS_FOR_PLAYERS = 'ORDER_ADD_ITEMS_FOR_PLAYERS';
export const ORDER_ADD_ITEMS_FOR_SELECTED_STAFF_MEMBERS = 'ORDER_ADD_ITEMS_FOR_SELECTED_STAFF_MEMBERS';
export const ORDER_UPSERT_ITEMS = 'ORDER_UPSERT_ITEMS';
export const ORDER_UPDATE_ITEMS = 'ORDER_UPDATE_ITEMS';
export const ORDER_SET_SORT_FIELD_REQUEST = 'ORDER_SET_SORT_FIELD_REQUEST';
export const ORDER_SET_SORT_FIELD_SUCCESS = 'ORDER_SET_SORT_FIELD_SUCCESS';
export const ORDER_UPDATE_PRICE_REQUEST = 'ORDER_UPDATE_PRICE_REQUEST';
export const ORDER_UPDATE_PRICE_SUCCESS = 'ORDER_UPDATE_PRICE_SUCCESS';
export const REMOVE_ITEMS = 'REMOVE_ITEMS';
export const REMOVE_FILE = 'REMOVE_FILE';
export const INITIALIZE_ORDER = 'INITIALIZE_ORDER';
export const CLEAR_ORDER = 'CLEAR_ORDER';
export const LOCK_NUMBERS = 'LOCK_NUMBERS';
export const FETCH_NUMBERS_SETTINGS_REQUEST = 'FETCH_NUMBERS_SETTINGS_REQUEST';
export const FETCH_NUMBERS_SETTINGS_SUCCESS = 'FETCH_NUMBERS_SETTINGS_SUCCESS';
export const FETCH_NUMBERS_SETTINGS_ERROR = 'FETCH_NUMBERS_SETTINGS_ERROR';
export const SET_SELECTED_PLAYERS = 'SET_SELECTED_PLAYERS';
export const SET_SELECTED_STAFF = 'SET_SELECTED_STAFF';
export const SET_SELECTED_PENDING_STAFF = 'SET_SELECTED_PENDING_STAFF';

export const ERROR = 'ERROR';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';

export const UPDATE_CLUB_REQUEST = 'UPDATE_CLUB_REQUEST';
export const UPDATE_CLUB_SUCCESS = 'UPDATE_CLUB_SUCCESS';
export const UPDATE_CLUB_ERROR = 'UPDATE_CLUB_ERROR';

export const PREVIEW_CSV_EVENT_REQUEST = 'PREVIEW_CSV_EVENT_REQUEST';
export const PREVIEW_CSV_EVENT_SUCCESS = 'PREVIEW_CSV_EVENT_SUCCESS';
export const PREVIEW_CSV_EVENT_ERROR = 'PREVIEW_CSV_EVENT_ERROR';

export const FETCH_MEMBERS_REQUEST = 'FETCH_MEMBERS_REQUEST';
export const FETCH_MEMBERS_SUCCESS = 'FETCH_MEMBERS_SUCCESS';
export const FETCH_MEMBERS_ERROR = 'FETCH_MEMBERS_ERROR';

export const SET_LOCK_NUMBERS_REQUEST = 'SET_LOCK_NUMBERS_REQUEST';
export const SET_LOCK_NUMBERS_SUCCESS = 'SET_LOCK_NUMBERS_SUCCESS';
export const SET_LOCK_NUMBERS_ERROR = 'SET_LOCK_NUMBERS_ERROR';

export const CHANGE_TEAM_STATUS_REQUEST = 'CHANGE_TEAM_STATUS_REQUEST';
export const CHANGE_TEAM_STATUS_SUCCESS = 'CHANGE_TEAM_STATUS_SUCCESS';
export const CHANGE_TEAM_STATUS_ERROR = 'CHANGE_TEAM_STATUS_ERROR';

export const TEAM_SEASON_TRANSFER_REQUEST = 'TEAM_SEASON_TRANSFER_REQUEST';
export const TEAM_SEASON_TRANSFER_SUCCESS = 'TEAM_SEASON_TRANSFER_SUCCESS';
export const TEAM_SEASON_TRANSFER_ERROR = 'TEAM_SEASON_TRANSFER_ERROR';

export const EDIT_TEAM_SETTINGS_REQUEST = 'EDIT_TEAM_SETTINGS_REQUEST';
export const EDIT_TEAM_SETTINGS_SUCCESS = 'EDIT_TEAM_SETTINGS_SUCCESS';
export const EDIT_TEAM_SETTINGS_ERROR = 'EDIT_TEAM_SETTINGS_ERROR';

export const ASSIGN_MEMBER_REQUEST = 'ASSIGN_MEMBER_REQUEST';
export const ASSIGN_MEMBER_SUCCESS = 'ASSIGN_MEMBER_SUCCESS';
export const ASSIGN_MEMBER_ERROR = 'ASSIGN_MEMBER_ERROR';

export const BULK_ASSIGN_MEMBERS_REQUEST = 'BULK_ASSIGN_MEMBERS_REQUEST';
export const BULK_ASSIGN_MEMBERS_SUCCESS = 'BULK_ASSIGN_MEMBERS_SUCCESS';
export const BULK_ASSIGN_MEMBERS_ERROR = 'BULK_ASSIGN_MEMBERS_ERROR';

export const FETCH_MEMBER_REQUEST = 'FETCH_MEMBER_REQUEST';
export const FETCH_MEMBER_SUCCESS = 'FETCH_MEMBER_SUCCESS';
export const FETCH_MEMBER_ERROR = 'FETCH_MEMBER_ERROR';

export const EDIT_ROLES_MEMBERS_REQUEST = 'EDIT_ROLES_MEMBERS_REQUEST';
export const EDIT_ROLES_MEMBERS_SUCCESS = 'EDIT_ROLES_MEMBERS_SUCCESS';
export const EDIT_ROLES_MEMBERS_ERROR = 'EDIT_ROLES_MEMBERS_ERROR';

export const CLEAR_TEAM_ID = 'CLEAR_TEAM_ID';
export const INVALID_CATALOG = 'INVALID_CATALOG';

export const TOR24_TEAMS_REQUEST = 'TOR24_TEAMS_REQUEST';
export const TOR24_TEAMS_SUCCESS = 'TOR24_TEAMS_SUCCESS';
export const TOR24_TEAMS_ERROR = 'TOR24_TEAMS_ERROR';

export const SET_TOR24_TEAMS_REQUEST = 'SET_TOR24_TEAMS_REQUEST';
export const SET_TOR24_TEAMS_SUCCESS = 'SET_TOR24_TEAMS_SUCCESS';
export const SET_TOR24_TEAMS_ERROR = 'SET_TOR24_TEAMS_ERROR';

export const SET_EXTERNAL_REF_REQUEST = 'SET_EXTERNAL_REF_REQUEST';
export const SET_EXTERNAL_REF_SUCCESS = 'SET_EXTERNAL_REF_SUCCESS';
export const SET_EXTERNAL_REF_ERROR = 'SET_EXTERNAL_REF_ERROR';

export const DELETE_EXTERNAL_REF_REQUEST = 'DELETE_EXTERNAL_REF_REQUEST';
export const DELETE_EXTERNAL_REF_SUCCESS = 'DELETE_EXTERNAL_REF_SUCCESS';
export const DELETE_EXTERNAL_REF_ERROR = 'DELETE_EXTERNAL_REF_ERROR';

export const FETCH_INDIVIDUAL_ORDERS_REQUEST = 'FETCH_INDIVIDUAL_ORDERS_REQUEST';
export const FETCH_INDIVIDUAL_ORDERS_SUCCESS = 'FETCH_INDIVIDUAL_ORDERS_SUCCESS';
export const FETCH_INDIVIDUAL_ORDERS_ERROR = 'FETCH_INDIVIDUAL_ORDERS_ERROR';

export const FETCH_TEAM_BASKET_ORDERS_REQUEST = 'FETCH_TEAM_BASKET_ORDERS_REQUEST';
export const FETCH_TEAM_BASKET_ORDERS_SUCCESS = 'FETCH_TEAM_BASKET_ORDERS_SUCCESS';
export const FETCH_TEAM_BASKET_ORDERS_ERROR = 'FETCH_TEAM_BASKET_ORDERS_ERROR';

export const FETCH_INVITATIONS_REQUEST = 'FETCH_INVITATIONS_REQUEST';
export const FETCH_INVITATIONS_ERROR = 'FETCH_INVITATIONS_ERROR';
export const FETCH_INVITATIONS_SUCCESS = 'FETCH_INVITATIONS_SUCCESS';
