import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, Dimmer, DropdownItemProps, DropdownProps, Form, Loader, Message,
} from 'semantic-ui-react';
import get from 'lodash/get';

import { useParams } from 'react-router';
import FormFields from '../../../../components/FormFields';
import Header from '../../../../components/Header';
import styles from './EditTeam.module.scss';
import useFocusFirstInput from '../../../../hooks/useFocusFirstInput';
import useForm from '../../../../hooks/useForm';
import { CLUB_TEAMS_MEMBERS_ROUTE } from '../../../../constants/routes';
import { EditTeamProps, ERROR_MESSAGE_TRANSLATIONS } from './EditTeamProps';
import { createTeamSchema, validationSchema } from './schema';
import {
  ExternalReferencedEntityType,
  ExternalSystemName,
  ExternalSystemReference,
} from '../../../../models/externalSystemIntegration';
import { Tor24Team } from '../../../../models/externalSystemIntegration/tor24Team';

const EditTeam = ({
  editTeam,
  clubId,
  fetchTeamId,
  teamsByHash,
  loading,
  error,
  errorMessage,
  fetchTeam,
  club,
  tor24Teams,
  externalTeamByHash,
  externalTeamById,
  fetchClubById,
  loadingExternalTeams,
}: EditTeamProps) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [translate] = useTranslation();
  const [availableTeams, setAvailableTeams] = useState<DropdownItemProps[]>([]);
  const [availableAges, setAvailableAges] = useState<DropdownItemProps[]>([]);
  const [selectedAge, setSelectedAge] = useState('');
  const [selectedTeamId, setSelectedTeamId] = useState<string>();
  const [tor24ClubRef, setTor24ClubRef] = useState<string>('');
  const teamId = get(useParams(), 'teamId') as string;
  const team = teamsByHash[teamId];

  const {
    values,
    errors,
    handleChange,
    validate,
    setValues,
  } = useForm(validationSchema);

  useFocusFirstInput();
  useEffect(() => {
    if (!teamId && clubId) {
      fetchTeamId(clubId);
    }
  }, [clubId, fetchTeamId, teamId]);
  useEffect(() => {
    fetchClubById();
  }, [clubId, fetchClubById]);
  useEffect(() => {
    if (externalTeamByHash && externalTeamById) {
      const externalAges = externalTeamById
        .map((extTeamId: number) => externalTeamByHash[extTeamId])
        .map((extTeam: Tor24Team) => extTeam.age);
      const dropDownAges = externalAges
        .filter((item, index) => externalAges.indexOf(item) === index)
        .map((item: string, index: number) => {
          if (item === '') {
            return {
              key: index,
              text: translate('NO_AGE'),
              value: 'none',
            };
          }
          return {
            key: index,
            text: item,
            value: item,
          };
        });
      setAvailableAges(dropDownAges);
    }
  }, [externalTeamByHash, externalTeamById, translate]);

  useEffect(() => {
    if (club.externalSystemReferences && clubId) {
      const tor24Reference = club.externalSystemReferences
        .find((externalSystemReference => externalSystemReference.externalSystemName === 'TOR_24'));
      if (tor24Reference) {
        setTor24ClubRef(tor24Reference.externalSystemEntityId);
        if (!externalTeamById.length && tor24ClubRef) {
          tor24Teams(tor24ClubRef);
        }
      }
    }
  }, [club, clubId, externalTeamById.length, tor24ClubRef, tor24Teams]);

  useEffect(() => {
    if (externalTeamByHash && externalTeamById) {
      const externalAges = externalTeamById
        .map((extTeamId: number) => externalTeamByHash[extTeamId])
        .map((extTeam: Tor24Team) => extTeam.age);
      const dropDownAges = externalAges
        .filter((item, index) => externalAges.indexOf(item) === index)
        .map((item: string, index: number) => {
          if (item === '') {
            return {
              key: index,
              text: translate('NO_AGE'),
              value: 'none',
            };
          }
          return {
            key: index,
            text: item,
            value: item,
          };
        });
      setAvailableAges(() => dropDownAges);
    }
  }, [externalTeamByHash, externalTeamById, translate]);

  useEffect(() => {
    if (externalTeamByHash && externalTeamById) {
      const externalTeams = externalTeamById
        .map((extTeamId: number) => externalTeamByHash[extTeamId]);
      const teamsDropdownItems = externalTeams.filter((item) => {
        if (selectedAge === 'none') {
          return item.age === '';
        }
        return item.age === selectedAge;
      })
        .map((extTeam, index) => ({ key: index, value: extTeam.id, text: extTeam.name }));
      setAvailableTeams(() => teamsDropdownItems);
    }
  }, [selectedAge, externalTeamByHash, externalTeamById]);

  const handleAgeSelection = (e: any, { value }: DropdownProps) => {
    setSelectedAge(() => value as string);
  };

  const handleTeamSelection = (e: any, { value }: DropdownProps) => {
    setSelectedTeamId(() => value as string);
  };
  const setExternalTeamId = useCallback((externalTeamId: string) => {
    if (team.externalSystemReferences) {
      const tooor24IntegrationIndex = team.externalSystemReferences
        .findIndex(
          (
            value: ExternalSystemReference,
          ) => value.externalSystemName === ExternalSystemName.TOR_24,
        );
      if (tooor24IntegrationIndex > -1) {
        const extRefs = team.externalSystemReferences;
        extRefs[tooor24IntegrationIndex].externalSystemEntityId = externalTeamId;
        return extRefs;
      }
      return [...team.externalSystemReferences, {
        entityType: ExternalReferencedEntityType.TEAM,
        entityId: +teamId,
        externalSystemName: ExternalSystemName.TOR_24,
        externalSystemEntityId: externalTeamId,
      }];
    }
    return [{
      entityType: ExternalReferencedEntityType.TEAM,
      entityId: +teamId,
      externalSystemName: ExternalSystemName.TOR_24,
      externalSystemEntityId: externalTeamId,
    }];
  }, [team, teamId]);

  useEffect(() => {
    if (team) {
      setValues((currentValues: any) => ({
        ...currentValues,
        teamName: team.name || null,
      }));
      if (team.externalSystemReferences) {
        const tooor24IntegrationIndex = team.externalSystemReferences
          .findIndex(
            (
              value: ExternalSystemReference,
            ) => value.externalSystemName === ExternalSystemName.TOR_24,
          );
        if (tooor24IntegrationIndex > -1 && externalTeamById.length > 0) {
          const externalTeam = externalTeamByHash[
            team.externalSystemReferences[tooor24IntegrationIndex].externalSystemEntityId
          ];
          if (externalTeam) {
            setSelectedTeamId(
              externalTeam.id,
            );
            setSelectedAge(externalTeam.age === '' ? 'none' : externalTeam.age);
          }
        }
      }
    }
  }, [team, setValues, setExternalTeamId, externalTeamByHash, externalTeamById.length]);

  useEffect(() => {
    if (teamId && clubId) {
      fetchTeam(+teamId);
    }
  }, [clubId, fetchTeam, teamId]);

  const handleSubmit = () => {
    setIsSubmitted(true);
    if (validate() && clubId) {
      const externalRefs = team.externalSystemReferences ? team.externalSystemReferences : [];
      const tooor24IntegrationIndex = externalRefs.findIndex(
        (
          value: ExternalSystemReference,
        ) => value.externalSystemName === ExternalSystemName.TOR_24,
      );
      if (tooor24IntegrationIndex > -1
          && selectedTeamId
          && selectedAge
          && externalRefs) {
        externalRefs[tooor24IntegrationIndex].externalSystemEntityId = selectedTeamId.toString();
      } else if (selectedAge && selectedTeamId) {
        externalRefs.push({
          entityType: ExternalReferencedEntityType.TEAM,
          entityId: +teamId,
          externalSystemName: ExternalSystemName.TOR_24,
          externalSystemEntityId: selectedTeamId,
        });
      }
      editTeam(
        {
          ...team,
          name: values.teamName,
          externalSystemReferences: externalRefs,
        },
        !selectedAge,
      );
    }
  };

  const renderButton = () => (
    <Button
      primary
      disabled={loading || loadingExternalTeams}
      type="submit"
      content={translate('SAVE')}
      form="create-form"
    />
  );

  const renderErrorMessage = () => (
    <Message
      error={error}
      header={translate('ERROR')}
      content={
        (errorMessage && !!Object.keys(ERROR_MESSAGE_TRANSLATIONS).includes(errorMessage))
          ? translate(
            ERROR_MESSAGE_TRANSLATIONS[errorMessage as keyof typeof ERROR_MESSAGE_TRANSLATIONS],
          ) : translate('GENERAL_ERROR')}
    />
  );

  return (
    <div className={styles.wrapper}>
      <Header
        backLabel="TO_MY_TEAMS"
        backLinkTo={CLUB_TEAMS_MEMBERS_ROUTE.replace(':teamId', teamId)}
        renderButton={renderButton}
        title={translate('EDIT_TEAM')}
        border
      />

      <Form error={error} onSubmit={handleSubmit} className={styles.form} id="create-form">
        {isSubmitted && errors && !loading && renderErrorMessage()}

        {loadingExternalTeams ? (
          <Dimmer active inverted className={styles.dimmer}>
            <Loader />
          </Dimmer>
        ) : (
          <>
            <FormFields
              errors={errors}
              handleChange={handleChange}
              schema={createTeamSchema}
              values={values}
            />
            <div hidden={!tor24ClubRef}>
              <Form.Field
                className={styles.formField}
                key="team-age-id"
              >
                <label>{translate('TEAM_AGE')}</label>
                <div className={styles.inputWithLabel}>
                  <Form.Dropdown
                    name="club-name"
                    placeholder={`${translate('CHOOSE_EXISTING_AGE')} (${availableAges.length || 0})`}
                    onChange={handleAgeSelection}
                    className={styles.clubNameCount}
                    options={[...availableAges]}
                    disabled={!availableAges.length}
                    loading={loadingExternalTeams}
                    selection
                    clearable
                    value={selectedAge || ''}
                  />
                </div>
              </Form.Field>
              <Form.Field
                hidden={!availableTeams.length || selectedAge === ''}
                className={styles.formField}
                key="external-team-id"
              >
                <label>{translate('TOR_24_TEAM')}</label>
                <div className={styles.inputWithLabel}>
                  <Form.Dropdown
                    name="club-name"
                    placeholder={`${translate('CHOOSE_TEAM')} (${availableTeams.length || 0})`}
                    onChange={handleTeamSelection}
                    className={styles.clubNameCount}
                    options={[...availableTeams]}
                    disabled={!availableTeams.length}
                    loading={loading}
                    selection
                    value={selectedTeamId}
                  />
                </div>
              </Form.Field>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};

export default EditTeam;
