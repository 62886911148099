import { Epic } from 'redux-observable';
import { addNotification } from './notifications';
import {
  fetchCatalog, fetchTags, fetchEmbellishments, validateCatalog,
} from './catalogs';
import { fetchFile } from './files';
import { fetchStaff } from './staff';
import {
  fetchTokenDetails, fetchTeamId, initializeApplicationParameters,
  startup, validateHobbyTeamToken, acceptInvitation, checkEmailAvailability,
  fetchAuthUser,
} from './application';
import {
  updateUser, uploadAvatar, fetchUserInfo, deleteData,
} from './users';
import { handleError } from './errors';
import { signUpWithTokenEpic, signUpSuccess } from './signUp';
import {
  fetchClub, fetchClubLogo, uploadClubLogo, updateClub,
} from './clubs';
import {
  createPlayer, fetchPlayer, fetchPlayers, updatePlayer,
} from './players';
import {
  createEvent, fetchEvents, fetchEvent, updateEvent, updateMatchResult, uploadEvents,
  deleteEvent, cancelEvent, previewCSVEvent, bulkDeleteEvent, createEvents,
} from './calendar';
import {
  createTeam, deactivateInviteToken, editTeamSettings, fetchInviteToken, fetchTeam, fetchTeams,
  generateInviteToken, sendInvitation, lockNumbers, deleteTeam, resendInvitation, changeTeamStatus,
  bulkDeactivateInviteToken, editTeam, teamSeasonTransfer,
} from './teams';
import {
  addItemsForPlayers, addItemsForSelectedStaffMembers,
  changeOrderStatus, deleteOrders, fetchNumbersSettings, fetchOrder, fetchOrders,
  initializeOrder, setStatusEpic, toggleAllPlayersSelection, toggleAllProductsSelection,
  togglePlayerSelection, toggleProductSelection, upsertOrder,
  orderUpdateItems, orderUpdatePrice, toggleStaffSelection,
} from './orders';
import {
  fetchMember, fetchMembers, assignMember, bulkAssignMembers, editRolesMembers, deleteMembers,
  deleteMember,
} from './members';
import {
  fetchTor24Teams, setTor24Team, setExternalSystemReference, fetchTor24Matches,
} from './externalSystems';
import deleteExternalSystemReference from './externalSystems/deleteExternalSystemReference';
import { fetchIndividualOrders } from './individualOrders';
import { fetchTeamBasketOrders } from './teamBasketOrders';
import { fetchInvitations } from './invitations';

const epics: Array<Epic> = [
  startup,
  signUpWithTokenEpic,
  addNotification,
  cancelEvent,
  handleError,
  validateHobbyTeamToken,
  signUpSuccess,
  fetchClub,
  fetchClubLogo,
  fetchFile,
  fetchTeams,
  resendInvitation,
  deleteTeam,
  fetchCatalog,
  bulkDeactivateInviteToken,
  fetchTeamId,
  fetchTor24Matches,
  initializeApplicationParameters,
  updateUser,
  createTeam,
  sendInvitation,
  fetchTeam,
  createPlayer,
  createEvent,
  fetchEvents,
  deleteEvent,
  fetchEvent,
  updateEvent,
  acceptInvitation,
  updateMatchResult,
  uploadClubLogo,
  deactivateInviteToken,
  deleteMember,
  deleteOrders,
  fetchEmbellishments,
  checkEmailAvailability,
  fetchPlayer,
  fetchPlayers,
  fetchStaff,
  deleteMembers,
  fetchInviteToken,
  fetchTags,
  generateInviteToken,
  fetchOrders,
  deleteData,
  updatePlayer,
  createEvents,
  fetchAuthUser,
  upsertOrder,
  fetchTokenDetails,
  changeOrderStatus,
  setStatusEpic,
  togglePlayerSelection,
  toggleStaffSelection,
  toggleProductSelection,
  toggleAllProductsSelection,
  toggleAllPlayersSelection,
  bulkDeleteEvent,
  addItemsForPlayers,
  addItemsForSelectedStaffMembers,
  initializeOrder,
  fetchOrder,
  fetchNumbersSettings,
  fetchStaff,
  editTeam,
  updateClub,
  uploadEvents,
  previewCSVEvent,
  fetchMember,
  fetchMembers,
  lockNumbers,
  assignMember,
  bulkAssignMembers,
  changeTeamStatus,
  editRolesMembers,
  validateCatalog,
  orderUpdateItems,
  orderUpdatePrice,
  uploadAvatar,
  fetchUserInfo,
  fetchTor24Teams,
  setTor24Team,
  setExternalSystemReference,
  deleteExternalSystemReference,
  fetchIndividualOrders,
  fetchTeamBasketOrders,
  fetchInvitations,
  teamSeasonTransfer,
  editTeamSettings,
];

export default epics;
