import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import EditTeam from './EditTeam';
import { State } from '../../../../models/State';
import {
  editTeamRequest,
  fetchTeamIdRequest, fetchTeamRequest,
  fetchTeamsRequest,
  setLockNumbersRequest,
} from '../../../../actions/teams';
import { fetchPlayersRequest, setSelectedPlayers } from '../../../../actions/players';
import { fetchStaffRequest } from '../../../../actions/staff';
import { tor24TeamsRequest } from '../../../../actions/externalSystems/tor24Teams';
import { Club } from '../../../../models/Club';
import { fetchClubRequest } from '../../../../actions/clubs';

const mapStatesToProps = ({
  application: { clubId },
  teams,
  players,
  staff,
  clubs,
  externalSystems: {
    externalTeamByHash,
    externalTeamById,
    loading,
  },
}: State) => ({
  clubId,
  club: clubId && clubs.byHash[clubId] && clubs.byHash[clubId].address
    ? clubs.byHash[clubId]
    : {
      address: {},
    } as Club,
  teamsById: teams.byId,
  teamsByHash: teams.byHash,
  loading: teams.loading,
  loadingExternalTeams: clubs.loading || loading,
  playersByHash: players.byHash,
  playersById: players.byId,
  playersLoading: players.loading,
  playerDeleted: players.playerDeleted,
  playersCount: players.count,
  selectedPlayers: players.selectedId,
  staffByHash: staff.byHash,
  editionSuccessful: teams.editionSuccessful,
  error: teams.error,
  errorMessage: teams.errorMessage,
  externalTeamByHash,
  externalTeamById,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchTeams: bindActionCreators(fetchTeamsRequest, dispatch),
  fetchTeam: bindActionCreators(fetchTeamRequest, dispatch),
  fetchPlayers: bindActionCreators(fetchPlayersRequest, dispatch),
  fetchStaff: bindActionCreators(fetchStaffRequest, dispatch),
  setSelectedPlayers: bindActionCreators(setSelectedPlayers, dispatch),
  fetchTeamId: bindActionCreators(fetchTeamIdRequest, dispatch),
  lockNumbers: bindActionCreators(setLockNumbersRequest, dispatch),
  editTeam: bindActionCreators(editTeamRequest, dispatch),
  tor24Teams: bindActionCreators(tor24TeamsRequest, dispatch),
  fetchClubById: bindActionCreators(fetchClubRequest, dispatch),
});

export default connect(mapStatesToProps, mapDispatchToProps)(EditTeam);
