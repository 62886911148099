import queryString from 'query-string';
import { Redirect, Switch, Route } from 'react-router-dom';
import React, {
  FC, useCallback, useEffect, useState,
} from 'react';

import Calendar from '../Calendar';
import Catalog from '../Catalog';
import ClubTeams from '../ClubTeams';
import CreateTeam from '../ClubTeams/components/CreateTeam';
import EditPlayer from '../PlayerEdit';
import InvitationModal from '../InvitationModal';
import Layout from '../../components/Layout';
import Notifications from './components/Notifications';
import OrderPreparation from '../OrderPreparation';
import Orders from '../Orders';
import Page404 from '../Page404';
import PlayerProfile from '../PlayerProfile';
import ProductDetails from '../ProductDetails';
import Profile from '../Profile';
import ProfileEdit from '../ProfileEdit';
import ProtectedRoute from './components/ProtectedRoute';
import ResetPassword from '../ResetPassword';
import Settings, { SettingsEdit } from '../Settings';
import CreateMemberSettings from '../Settings/components/CreateMember';
import SignIn from '../SignIn';
import SignUp from '../SignUp';
import TeamsTeamLead from '../TeamsTeamLead';
import UnprotectedRoute from './components/UnprotectedRoute';
import { AppProps } from './AppProps';
import { Roles } from '../../constants/roles';
import { UrlParams } from '../../constants/urlParams';
import {
  adminMenuItems,
  teamLeaderMenuItems,
  staticMenuItems,
  authenticatedMenuStaticItem,
  unAuthenticatedMenuStaticItem,
} from './menuItems';
import {
  ADD_NEW_MEMBER_ROUTE,
  CALENDAR_ROUTE,
  CATALOG_PRODUCT_ROUTE,
  CATALOG_ROUTE,
  CLUB_ORDERS_ROUTE,
  CLUB_TEAMS_CREATE_MEMBER_ROUTE,
  CLUB_TEAMS_MEMBERS_ROUTE,
  CLUB_TEAMS_ROUTE,
  DATA_PRIVACY_ROUTE,
  EDIT_PLAYER_ROUTE,
  IMPRINT_ROUTE,
  MEMBER_ROUTE,
  NEW_CLUB_TEAM_ROUTE,
  NEW_MEMBER_ROUTE,
  ORDER_PREPARATION_ROUTE,
  ORDER_ROUTE,
  PLAYER_PROFILE_ROUTE,
  PROFILE_EDIT_ROUTE,
  PROFILE_ROUTE,
  RESET_PASSWORD,
  SETTINGS_EDIT_ROUTE,
  SETTINGS_ROUTE,
  SIGN_IN_ROUTE,
  SIGN_UP_ROUTE,
  TEAM_ORDERS_ROUTE,
  TEAM_VIEW_ROUTE,
  TERMS_ROUTE,
  HOBBY_TEAM_ROUTE,
  SIGNUP_WELCOME_PAGE_ROUTE,
  DELETE_DATA_ROUTE,
  DELETE_DATA_CONFIRMATION_PAGE,
  USER_CONSENT_ROUTE,
  CLUB_TEAMS_EDIT_ROUTE,
  JOIN_TEAM_AND_CLUB_SIGNUP_APPROVED_ROUTE,
  JOIN_OFFICIAL_CLUB_SIGNUP_APPROVED_ROUTE,
  JOIN_TEAM_AND_CLUB_REJECTED_ROUTE,
  JOIN_OFFICIAL_CLUB_REJECTED_ROUTE,
  SELF_SIGNUP_GENERAL_ROUTE,
  NAVIGATION_ROUTE,
  TEAM_EDIT_ROUTE,
  CLUB_INDIVIDUAL_ORDERS_ROUTE,
  CLUB_TEAM_BASKET_ORDERS_ROUTE,
  ADIDAS_RUNNING_OAUTH_SUCCESS_ROUTE, TEAM_SETTINGS_ROUTE,
} from '../../constants/routes';
import MembersList from '../ClubTeams/components/MembersList';
import MemberView from '../Settings/components/MemberView';
import CreateMember from '../ClubTeams/components/CreateMember';
import InactiveTeamPopup from '../InactiveTeamPopup';
import AsyncStaticPage from './components/AsyncStaticPage';
import StaticPage from './components/StaticPage';
import HobbyTeamWelcome from '../HobbyTeamWelcome';
import SignupWelcomePage from '../SignupWelcomePage';
import DeleteData from '../DeleteData';
import DeleteDataConfirmation from '../DeleteDataConfirmation';
import { jwtDecoder } from '../../utils/decoders/jwtDecoder';
import { InvitationQueryParams } from '../../models/InvitationData';
import EditTeam from '../ClubTeams/components/EditTeam';
import MobileBlockerPage from '../MobileBlockerPage/MobileBlockerPage';
import PlainInfoPage from './components/PlainInfoPage';
import { ViewportProvider } from '../../hooks/useViewport';
import MobileNavigation from '../MobileNavigation';
import EditTeamTeamLead from '../EditTeamTeamLead';
import IndividualOrders from '../IndividualOrders';
import TeamBasketOrders from '../TeamBasketOrders';
import TeamSettings from '../ClubTeams/components/TeamSettings';

const App: FC<AppProps> = ({
  menuExpanded, setMenuExpanded, location, fetchTeamId,
  clubLogoUrl, isAuthenticated, teamId, clubId, userRole,
}) => {
  const queryParams = queryString.parse(location.search);
  const [invitation, setInvitation] = useState<InvitationQueryParams | null>();
  const inviteJwtToken = queryParams[UrlParams.INVITATION_TOKEN] as string;
  useEffect(() => {
    if (clubId) {
      fetchTeamId(clubId);
    }
  }, [clubId, fetchTeamId]);

  useEffect(() => {
    if (inviteJwtToken) {
      setInvitation(jwtDecoder(inviteJwtToken, UrlParams.INVITATION_TOKEN));
    }
  }, [inviteJwtToken]);

  const renderInvitationModal = useCallback(() => {
    if (inviteJwtToken && invitation && isAuthenticated) {
      return (
        <InvitationModal
          inviteToken={invitation.invitationToken}
          invitingPerson={invitation.invitedBy}
          invitingClub={invitation.clubName}
        />
      );
    }
    return null;
  }, [inviteJwtToken, invitation, isAuthenticated]);

  const renderMobileBlocker = useCallback(() => {
    if (userRole !== Roles.PLAYER) {
      return (
        <MobileBlockerPage />
      );
    }
    return null;
  }, [userRole]);

  const mobileAccessProtected = useCallback((Component: any) => (props: any) => (
    <>
      <Component {...props} />
      {renderMobileBlocker()}
    </>
  ), [renderMobileBlocker]);

  const renderStaticPages = useCallback((Component: any) => (props: any) => (
    <Layout
      clubLogoUrl={clubLogoUrl}
      menuExpanded={menuExpanded}
      menuItems={[
        isAuthenticated ? authenticatedMenuStaticItem(userRole) : unAuthenticatedMenuStaticItem,
        ...staticMenuItems,
      ]}
      setMenuExpanded={setMenuExpanded}
    >
      <Component {...props} />
    </Layout>
  ), [setMenuExpanded, menuExpanded, clubLogoUrl, isAuthenticated, userRole]);

  const renderRoutes = useCallback(() => {
    switch (userRole) {
      case Roles.CLUB_ADMIN:
        return [
          <ProtectedRoute exact path={ADD_NEW_MEMBER_ROUTE} render={mobileAccessProtected(CreateMemberSettings)} key="settings-edit" />,
          <ProtectedRoute exact path={MEMBER_ROUTE} render={mobileAccessProtected(MemberView)} key="member-view" />,
          <ProtectedRoute exact path={SETTINGS_EDIT_ROUTE} render={mobileAccessProtected(SettingsEdit)} key="settings-edit" />,
          <ProtectedRoute exact path={SETTINGS_ROUTE} render={mobileAccessProtected(Settings)} key="settings" />,
          <ProtectedRoute exact path={CALENDAR_ROUTE} render={mobileAccessProtected(Calendar)} key="calendar" />,
          <ProtectedRoute exact path={CLUB_ORDERS_ROUTE} component={Orders} key="club-orders" />,
          <ProtectedRoute exact path={CLUB_INDIVIDUAL_ORDERS_ROUTE} component={IndividualOrders} key="club-orders" />,
          <ProtectedRoute exact path={CLUB_TEAM_BASKET_ORDERS_ROUTE} component={TeamBasketOrders} key="team-basket-orders" />,
          <ProtectedRoute exact path={ORDER_ROUTE} component={OrderPreparation} key="order-preparation" />,
          <ProtectedRoute exact path={ORDER_PREPARATION_ROUTE} component={OrderPreparation} key="order-preparation" />,
          <ProtectedRoute exact path={CLUB_TEAMS_MEMBERS_ROUTE} render={mobileAccessProtected(MembersList)} key="club-teams-members" />,
          <ProtectedRoute exact path={CLUB_TEAMS_EDIT_ROUTE} render={mobileAccessProtected(EditTeam)} key="club-teams-edit" />,
          <ProtectedRoute exact path={CLUB_TEAMS_CREATE_MEMBER_ROUTE} render={mobileAccessProtected(CreateMember)} key="club-teams-create-member" />,
          <ProtectedRoute exact path={TEAM_SETTINGS_ROUTE} render={mobileAccessProtected(TeamSettings)} key="tl-team-settings" />,
          <ProtectedRoute exact path={CLUB_TEAMS_ROUTE} render={mobileAccessProtected(ClubTeams)} key="club-teams" />,
          <ProtectedRoute exact path={NEW_CLUB_TEAM_ROUTE} render={mobileAccessProtected(CreateTeam)} key="club-teams-new" />,
          <ProtectedRoute exact path={CATALOG_ROUTE} render={mobileAccessProtected(Catalog)} key="catalog" />,
          <ProtectedRoute exact path={CATALOG_PRODUCT_ROUTE} render={mobileAccessProtected(ProductDetails)} key="product" />,
          <ProtectedRoute exact path={PROFILE_ROUTE} render={mobileAccessProtected(Profile)} key="profile" />,
          <ProtectedRoute exact path={EDIT_PLAYER_ROUTE} render={mobileAccessProtected(EditPlayer)} key="team-player-edit" />,
          <ProtectedRoute exact path={PLAYER_PROFILE_ROUTE} render={mobileAccessProtected(PlayerProfile)} key="player-profile" />,
          <ProtectedRoute exact path={PROFILE_EDIT_ROUTE} render={mobileAccessProtected(ProfileEdit)} key="profile-edit" />,
          <Redirect to={`${CLUB_TEAMS_ROUTE}${location ? location.search : ''}`} key="club-default" />,
        ];
      case Roles.TEAM_LEAD:
        return [
          <ProtectedRoute exact path={TEAM_ORDERS_ROUTE} component={Orders} key="team-orders" />,
          <ProtectedRoute exact path={ORDER_ROUTE} component={OrderPreparation} key="team-order" />,
          <ProtectedRoute exact path={ORDER_PREPARATION_ROUTE} component={OrderPreparation} key="team-order-preparation" />,
          <ProtectedRoute exact path={CALENDAR_ROUTE} render={mobileAccessProtected(Calendar)} key="calendar" />,
          <ProtectedRoute exact path={NEW_MEMBER_ROUTE} render={mobileAccessProtected(CreateMember)} key="team-player-new" />,
          <ProtectedRoute exact path={EDIT_PLAYER_ROUTE} render={mobileAccessProtected(EditPlayer)} key="team-player-edit" />,
          <ProtectedRoute exact path={PLAYER_PROFILE_ROUTE} render={mobileAccessProtected(PlayerProfile)} key="player-profile" />,
          <ProtectedRoute exact path={TEAM_VIEW_ROUTE} render={mobileAccessProtected(TeamsTeamLead)} key="team" />,
          <ProtectedRoute exact path={TEAM_EDIT_ROUTE} render={mobileAccessProtected(EditTeamTeamLead)} key="tl-team-edit" />,
          <ProtectedRoute exact path={TEAM_SETTINGS_ROUTE} render={mobileAccessProtected(TeamSettings)} key="tl-team-settings" />,
          <ProtectedRoute exact path={CATALOG_ROUTE} render={mobileAccessProtected(Catalog)} key="catalog" />,
          <ProtectedRoute exact path={CATALOG_PRODUCT_ROUTE} render={mobileAccessProtected(ProductDetails)} key="product-details" />,
          <ProtectedRoute exact path={PROFILE_ROUTE} render={mobileAccessProtected(Profile)} key="profile" />,
          <ProtectedRoute exact path={PROFILE_EDIT_ROUTE} render={mobileAccessProtected(ProfileEdit)} key="profile-edit" />,
          teamId
            ? <Redirect to={`${TEAM_VIEW_ROUTE.replace(':teamId', String(teamId))}${location ? location.search : ''}`} key="team-default" />
            : <Redirect to={SIGN_IN_ROUTE} key="noteam-redirection" />,
        ].filter(Boolean);
      case Roles.PLAYER:
        return [
          teamId
            ? <Redirect to={`${SIGNUP_WELCOME_PAGE_ROUTE}${location ? location.search : ''}`} key="mobile-app-redirect-default" />
            : <Redirect to={SIGN_IN_ROUTE} key="noteam-redirection" />,
        ];
      default:
        return [
          !isAuthenticated && <Redirect to={`${inviteJwtToken ? SIGN_UP_ROUTE : SIGN_IN_ROUTE}${location ? location.search : ''}`} key="default-redirect" />,
        ].filter(Boolean);
    }
  }, [userRole, mobileAccessProtected,
    location, teamId, isAuthenticated, inviteJwtToken]);

  return (
    <>
      <ViewportProvider>
        <Switch>
          <UnprotectedRoute exact path={SIGN_IN_ROUTE} component={SignIn} />
          <Route exact path={RESET_PASSWORD} component={ResetPassword} />
          <UnprotectedRoute path={SIGN_UP_ROUTE} component={SignUp} />
          <Route path={HOBBY_TEAM_ROUTE} component={HobbyTeamWelcome} />
          <Route path={NAVIGATION_ROUTE} component={MobileNavigation} />
          <Route path={DELETE_DATA_ROUTE} component={DeleteData} />
          <Route path={DELETE_DATA_CONFIRMATION_PAGE} component={DeleteDataConfirmation} />
          <Route path={SIGNUP_WELCOME_PAGE_ROUTE} component={SignupWelcomePage} />
          <Route path={TERMS_ROUTE} component={renderStaticPages(AsyncStaticPage)} />
          <Route path={IMPRINT_ROUTE} component={renderStaticPages(StaticPage)} />
          <Route path={USER_CONSENT_ROUTE} component={renderStaticPages(StaticPage)} />
          <Route path={DATA_PRIVACY_ROUTE} component={renderStaticPages(AsyncStaticPage)} />
          <Route
            path={ADIDAS_RUNNING_OAUTH_SUCCESS_ROUTE}
            component={renderStaticPages(StaticPage)}
          />
          <Route path={JOIN_OFFICIAL_CLUB_SIGNUP_APPROVED_ROUTE} component={PlainInfoPage} />
          <Route path={JOIN_TEAM_AND_CLUB_SIGNUP_APPROVED_ROUTE} component={PlainInfoPage} />
          <Route path={JOIN_TEAM_AND_CLUB_REJECTED_ROUTE} component={PlainInfoPage} />
          <Route path={JOIN_OFFICIAL_CLUB_REJECTED_ROUTE} component={PlainInfoPage} />
          <Route path={SELF_SIGNUP_GENERAL_ROUTE} component={PlainInfoPage} />
          <Layout
            clubLogoUrl={clubLogoUrl}
            menuExpanded={menuExpanded}
            menuItems={userRole === Roles.CLUB_ADMIN
              ? adminMenuItems({ teamId, clubId })
              : teamLeaderMenuItems({ teamId, clubId })
            }
            setMenuExpanded={setMenuExpanded}
          >
            <Switch>
              {renderRoutes()}
            </Switch>
            {renderInvitationModal()}
          </Layout>
          <Route component={Page404} key="404" />
        </Switch>
        <Notifications />
        <InactiveTeamPopup />
      </ViewportProvider>
    </>
  );
};

export default App;
