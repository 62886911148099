import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { AjaxError } from 'rxjs/ajax';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { push } from 'connected-react-router';

import serializeTeam from '../../utils/serializers/serializeTeam';
import { CREATE_TEAM_ERROR, CREATE_TEAM_REQUEST, CREATE_TEAM_SUCCESS } from '../../constants/actionTypes';
import { CreateTeamRequestAction, NotificationType } from '../../models/actions';
import { State } from '../../models/State';
import { TEAMS_PATH } from '../../constants/paths';
import { CLUB_TEAMS_ROUTE } from '../../constants/routes';
import { addNotification } from '../../actions/notifications';
import { createTeamError, createTeamSuccess } from '../../actions/teams';
import { handleError } from '../../actions/errors';

export default (
  action$: ActionsObservable<CreateTeamRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(CREATE_TEAM_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{
    payload: {
      clubId, name, season, seasonStartDate, seasonEndDate,
    },
  }, {
    authentication: { authToken },
  }]) => ajax({
    url: TEAMS_PATH.replace('$clubId', `${clubId}`),
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
    body: JSON.stringify({ name, teamSettings: { season, seasonStartDate, seasonEndDate } }),
  }).pipe(
    mergeMap(({ response: createdTeam }) => ([
      addNotification(NotificationType.SUCCESS, CREATE_TEAM_SUCCESS),
      createTeamSuccess(serializeTeam(createdTeam)),
      push(CLUB_TEAMS_ROUTE),
    ].filter(Boolean))),
    catchError((error: AjaxError) => [
      handleError(error, CREATE_TEAM_ERROR),
      createTeamError(error),
    ]),
  )),
);
